import React from 'react';

export const Section = ({ 
  children,
  title,
  subTitle,
}) => {
    return (
    <section className="px-4 max-w-4xl mx-auto flex flex-col md:flex-row md:gap-16 my-8" >
      <div className='md:w-4/12'>
        <span className="text-gray-300 text-md leading-relaxed">
            {subTitle}
        </span>
        <h2 className="text-2xl mb-6 font-mono">{title}</h2>
        </div>
        <div className='md:w-8/12 space-y-6'>
          {children}
        </div>
    </section>
    );
};

export const CustomList = ({ as: Component = 'ul', children, className = '' }) => {
  return (
    <Component className={`my-6 space-y-2 ${className}`}>
      {children}
    </Component>
  );
};

export const CustomListItem = ({ children }) => {
  return (
    <li className="relative pl-6 before:absolute before:left-1 before:top-3 before:h-1.5 before:w-1.5 before:rounded-full before:bg-gray-600">
      {children}
    </li>
  );
};

export const OrderedListItem = ({ children, index }) => {
  return (
    <li className="relative pl-6">
      <span className="absolute left-0 top-0 text-gray-600 font-medium">
        {index}.
      </span>
      {children}
    </li>
  );
};