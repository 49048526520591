import "./src/styles/global.css"
// import "./src/styles/main.scss"

export const onClientEntry = () => {
  const link = document.createElement("link");
  link.href = "https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);

  (function (c, l, a, r, i, t, y) {
    c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
    t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", "qc7ugr3f5i");
};

export const onRenderBody = ({ setHtmlAttributes }) => {
  setHtmlAttributes({ lang: "en" });
};

export { wrapRootElement } from './src/gatsby-shared'
