import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { Section, CustomList, CustomListItem } from './components/mdx'

const components = {
    h3: (props) => (
      <h3 className="text-3xl font-bold text-white " {...props} />
    ),
    h4: (props) => (
      <h4 className="text-xl font-semibold text-white" {...props} />
    ),
    p: (props) => (
      <p className="text-gray-200 mt-0" {...props} />
    ),
    img: (props) => (
      <img className="mt-4" {...props} />
    ),
    ul: (props) => <CustomList {...props} />,
    ol: (props) => <CustomList as="ol" {...props} />,
    li: CustomListItem,
    blockquote: (props) => (
      <blockquote className="bg-gray-800 text-gray-100 p-6 rounded-lg border-l-4 border-blue-400">
          {props.children}
      </blockquote>
    ),
    Section,
  };

  export const wrapRootElement = ({ element }) => (
    <MDXProvider components={components}>{element}</MDXProvider>
  )